import adapter from 'webrtc-adapter';
import {
  AccountDto,
  AccountDtoGenderEnum,
  AccountGroupDto,
  AuthResponseDto,
  MeetingCountDtoKeyEnum,
  MeetingDto,
  MemberAnswerGroupDto,
  MemberDto,
  MemberDtoRolesEnum,
  ParticipantDto,
  ParticipantDtoGenderEnum,
  ParticipantGroupDto,
  PublicMeetingDto,
  PublicParticipantDto,
  SearchOptionsFilterEnum
} from '../gen/client';
import {DEFAULT_PHONE_PREFIX, ERROR_MESSAGE_INTERVAL, MEETING_DATE_FORMAT, MONTHS, SUCCESS_MESSAGE_INTERVAL} from './constants';
import moment, {Moment} from 'moment';
import {env} from '../env/env';
import Storage, {STORAGE_ROLE} from './Storage';
import {Dimensions} from './enums';
import {message, notification} from 'antd';
import {MemberAtom} from "../state/logged";
import {DatePickerInterval} from "../components/general/IntervalPicker";
import _ from 'lodash';
import {AspectRatio, MeetingInfo, MeetingSession} from './types';
import {isValidEmail, isValidNumber} from './validators';
import {isAppRoute} from "./routes";

const rolesOrder = Object.values(MemberDtoRolesEnum);

// const throttledErrorMessage = (msg: string) => _.throttle(() => {
//   console.log('=> in throttled with ' + msg);
//   errorMessage(msg);
// }, 500);

export function successMessage(text: string) {
  message.success(text, SUCCESS_MESSAGE_INTERVAL);
}

export function errorMessage(text: string, interval: number = ERROR_MESSAGE_INTERVAL, throttled: boolean = false): any {
  // if (throttled) {
  //   console.log('=> showing throttled message');
  //   throttledErrorMessage(text);
  // } else {
  message.error(text, interval);
  // }
  return null;
}

export function warnMessage(text: string, interval: number = SUCCESS_MESSAGE_INTERVAL) {
  message.warn(text, interval);
}

export function getInitials(name: string) {
  if (!name) return;
  if (name.indexOf(' ') === -1) return name[0];

  const [first, last] = name.split(' ');
  return (first ? first[0] : '') + (last ? (first ? ' ' : '') + last[0] : '');
}

export function tinyDate(date: Moment): string {
  return date ? `${date.date()}. ${MONTHS[date.month()]}` : '';
}

export function format(date: Moment): string {
  return date.format(`${MEETING_DATE_FORMAT} hh:mm:ss`);
}

export function formatDate(date: Moment, pattern: string = MEETING_DATE_FORMAT): string {
  return date.clone().format(pattern);
}

export function formatTime(date: Moment): string {
  return date.clone().format('HH:mm');
}

export function parseTime(value: string): Moment {
  return moment(value, 'HH:mm');
}

export function timestamp(value: Moment): number {
  if (value === null) return null;
  return value.unix();
}

export function fromTimestamp(ts: number): Moment {
  return moment.unix(ts);
}

export function getServerDate(date: string) {
  return Date.parse(date);
}

export function downloadFile(fileContent: string, fileName: string) {
  const url = window.URL.createObjectURL(new Blob(["\ufeff", fileContent]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}

export function downloadFileUsingBlob(blob: Blob, fileName: string) {
  if (fileName.endsWith('.pdf')) {
    blob = new Blob([blob], {type: "application/pdf"})
  }

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}

function fallbackClipboardCopy(text: string) {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  document.execCommand('copy');
  document.body.removeChild(textArea);
}

export function clipboardCopy(text: string) {
  if (!navigator.clipboard) {
    fallbackClipboardCopy(text);
    return;
  }
  navigator.clipboard.writeText(text).then((() => {
  }));
}


export function generateCharArray(charA: string, charZ: string) {
  const letters = [];
  let initialIndex = charA.charCodeAt(0);
  const finalIndex = charZ.charCodeAt(0);
  for (; initialIndex <= finalIndex; ++initialIndex) {
    letters.push(String.fromCharCode(initialIndex));
  }
  return letters;
}

export function sortAscendingByName(list: AccountDto[]): AccountDto[] {
  return list.sort((c1, c2) => {
    return c1.name.toUpperCase() < c2.name.toUpperCase() ? -1 : 1;
  });
}

export function sortDescendingByName(list: AccountDto[]): AccountDto[] {
  return list.sort((c1, c2) => {
    return c1.name.toUpperCase() > c2.name.toUpperCase() ? -1 : 1;
  });
}

export function sortAscendingMemberByLastName(list: MemberDto[]): MemberDto[] {
  return list.sort((c1, c2) => {
    return c1.lastName.toUpperCase() < c2.lastName.toUpperCase() ? -1 : 1;
  });
}

export function sortDescendingMemberByLastName(list: MemberDto[]): MemberDto[] {
  return list.sort((c1, c2) => {
    return c1.lastName.toUpperCase() > c2.lastName.toUpperCase() ? -1 : 1;
  });
}

export function getHighestRole(roles: MemberDtoRolesEnum[]): MemberDtoRolesEnum {
  return roles.sort((a: MemberDtoRolesEnum, b: MemberDtoRolesEnum): number => rolesOrder.indexOf(a) - rolesOrder.indexOf(b))[0];
}

export function getDurationFromHoursAndMinutes(hours: number, minutes: number): number {
  return hours * 60 + minutes;
}

export function getHours(duration: number): number {
  return !!duration ? Math.floor(duration / 60) : 0;
}

export function getMinutes(duration: number): number {
  return !!duration ? duration % 60 : 0;
}

export function displayTime(hours: number, minutes: number): string {
  return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
}

export function getMeetingLink(meeting: MeetingDto | PublicMeetingDto) {
  return `${env.basePath}/meeting/${meeting.publicId}/preview`;
}

export function getMeetingLinkLabel(meeting: MeetingDto | PublicMeetingDto) {
  return `app/meeting/${meeting.publicId}`;
}

export function logout() {
  Storage.removeMemberId();
  Storage.removeToken();
  Storage.removeIsRefreshing();
  Storage.removeParticipantId();
  Storage.removeValue(STORAGE_ROLE);
  Storage.removeShowLoginHint();
  Storage.removeShowTemporaryInfo();
}

export function hex2rgba(hex: string, alpha = 1): string {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
}

export function getApplicationSizeClass(dimension: Dimensions): string {
  return dimension === Dimensions.DESKTOP ? 'desktop' : (dimension === Dimensions.TABLET ? 'tablet' : 'mobile');
}

export function initialStorageSetup(authResponse: AuthResponseDto, activeRole: MemberDtoRolesEnum) {
  Storage.setToken(authResponse.token);
  Storage.setMemberId(authResponse.member.id);
  Storage.removeIsRefreshing();
  Storage.setShowTemporaryInfo(true);

  if (JSON.parse(Storage.getRememberMe())) {
    Storage.setUserEmail(authResponse.member.email);
  } else {
    Storage.removeUserEmail();
  }
  if (authResponse.device) {
    Storage.setDeviceId(authResponse.device, authResponse.member.email);
  }
  if (!!authResponse.member.showLoginHint && authResponse.member.showLoginHint && activeRole === MemberDtoRolesEnum.PracticeManager) {
    Storage.setShowLoginHint(true);
  }
}

export function formatDuration(duration: number) {
  const millis = moment.duration(duration, 'minutes').as('milliseconds');
  const minutesSeconds = moment.utc(millis).format("mm:ss");
  const hours = moment.duration(millis).asHours().toFixed();
  return `${(hours.length === 1 ? '0' : '')}${hours}:${minutesSeconds}`;
}

export function getSearchFilter(filter: MeetingCountDtoKeyEnum): SearchOptionsFilterEnum {
  switch (filter) {
    case MeetingCountDtoKeyEnum.All:
      return SearchOptionsFilterEnum.All;
    case MeetingCountDtoKeyEnum.Cancelled:
      return SearchOptionsFilterEnum.Cancelled;
    case MeetingCountDtoKeyEnum.Future:
      return SearchOptionsFilterEnum.Future;
    case MeetingCountDtoKeyEnum.Past:
      return SearchOptionsFilterEnum.Past;
  }
}

export function hasPracticeManagerRole(roles: MemberDtoRolesEnum[]): boolean {
  return roles.indexOf(MemberDtoRolesEnum.PracticeManager) > -1;
}

export function hasCustomerCareRole(roles: MemberDtoRolesEnum[]): boolean {
  return roles.indexOf(MemberDtoRolesEnum.CustomerCare) > -1;
}

export function hasProviderRole(roles: MemberDtoRolesEnum[]): boolean {
  return roles.indexOf(MemberDtoRolesEnum.Provider) > -1;
}

export function hasAdminRole(roles: MemberDtoRolesEnum[]): boolean {
  return roles.indexOf(MemberDtoRolesEnum.Admin) > -1;
}

export function isCustomerAccount(roles: MemberDtoRolesEnum[]): boolean {
  return !hasAdminRole(roles) && !hasCustomerCareRole(roles);
}

export function isProvider(logged: MemberAtom): boolean {
  return logged.activeRole === MemberDtoRolesEnum.Provider;
}

export function isPracticeManager(logged: MemberAtom): boolean {
  return logged.activeRole === MemberDtoRolesEnum.PracticeManager;
}

export function isAdmin(logged: MemberAtom): boolean {
  return logged.activeRole === MemberDtoRolesEnum.Admin;
}

export function isCustomerCare(logged: MemberAtom): boolean {
  return logged.activeRole === MemberDtoRolesEnum.CustomerCare;
}

export function isAdminOrCustomerCare(logged: MemberAtom): boolean {
  return logged.activeRole === MemberDtoRolesEnum.CustomerCare || logged.activeRole === MemberDtoRolesEnum.Admin;
}

export function getFromDateFromInterval(interval: [Moment, Moment]) {
  return timestamp(moment(interval[0]));
}

export function getToDateFromInterval(interval: [Moment, Moment]) {
  return timestamp(moment(interval[1]).startOf('day').add(1, 'day'));
}

export function nextTick(callback: () => void) {
  setTimeout(callback, 0);
}

export function removePhonePrefix(value: string) {
  return value && value.startsWith(DEFAULT_PHONE_PREFIX) ? value.substring(DEFAULT_PHONE_PREFIX.length) : value;
}

export function intervalStart(interval: DatePickerInterval): number {
  return timestamp(moment(interval[0]).startOf('day'));
}

export function intervalEnd(interval: DatePickerInterval): number {
  return timestamp(moment(interval[1]).startOf('day').add(1, 'day'));
}

export function openNotification(description: any) {
  notification.open({
    message: '',
    description: description,
    placement: 'topRight',
    className: 'notification',
    duration: 5
  });
}

export function getDownloadSpeed(endTime: number, startTime: number, downloadSize: number): number {
  const duration = (endTime - startTime) / 1000; // ms -> s
  const bitsLoaded = downloadSize * 8;
  const speedBps = bitsLoaded / duration;
  const speedKbps = speedBps / 1024;
  return speedKbps / 1024;
}

export function arrayBufferToBase64(buffer: any) {
  let binary = '';
  let bytes = new Uint8Array(buffer);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function areArraysEqual(x: any[], y: any[]) {
  return _(x).xorWith(y, _.isEqual).isEmpty();
}

export function getWidthFromAspectRatio(ratio: AspectRatio) {
  return ratio.split('/')[0];
}

export function getHeightFromAspectRatio(ratio: AspectRatio) {
  return ratio.split('/')[1];
}

export function getAspectRatio(width: number, height: number): AspectRatio {
  let ratio = width / height;

  switch (ratio) {
    case 3 / 4:
      return AspectRatio.RATIO_3_4;
    case 4 / 3:
      return AspectRatio.RATIO_4_3;

    case 9 / 16:
      return AspectRatio.RATIO_9_16;
    case 16 / 9:
      return AspectRatio.RATIO_16_9;

    case 5 / 4:
      return AspectRatio.RATIO_5_4;
    case 4 / 5:
      return AspectRatio.RATIO_4_5;

    case 5 / 3:
      return AspectRatio.RATIO_5_3;
    case 3 / 5:
      return AspectRatio.RATIO_3_5;

    default:
      return AspectRatio.RATIO_16_9;
  }
}

export function getAspectRatioAsValue(width: number, height: number): number {
  const ratio = getAspectRatio(width, height);

  switch (ratio) {
    case AspectRatio.RATIO_3_4:
      return 3 / 4;
    case AspectRatio.RATIO_4_3:
      return 4 / 3;

    case AspectRatio.RATIO_9_16:
      return 9 / 16;
    case AspectRatio.RATIO_16_9:
      return 16 / 9;

    case AspectRatio.RATIO_5_4:
      return 5 / 4;
    case AspectRatio.RATIO_4_5:
      return 4 / 5;

    case AspectRatio.RATIO_5_3:
      return 5 / 3;
    case AspectRatio.RATIO_3_5:
      return 3 / 5;

    default:
      return 16 / 9;
  }
}

export function getNameByMeetingWSId(meeting: MeetingDto | PublicMeetingDto, meetingInfo: MeetingInfo, uuid: string) {
  let sender = '';

  try {
    const participantId = meetingInfo.sessions.find(it => it.id === uuid).memberOrParticipantId;
    let candidate = fetchMeetingParticipants(meeting).find(it => it.publicId === participantId);

    if (!!candidate) {
      sender = candidate.name;
    } else if (meeting.provider.id === participantId) {
      sender = `${meeting.provider.firstName} ${meeting.provider.lastName}`;
    }
  } catch (e) {
  }

  return sender;
}

export function getOwnName(meeting: MeetingDto | PublicMeetingDto, meetingSessions: MeetingSession[], memberOrParticipantId?: string) {
  if (!memberOrParticipantId) {
    memberOrParticipantId = Storage.getParticipantId() || Storage.getMemberId();
  }
  let name = '';

  try {
    const participantId = meetingSessions.find(it => it.memberOrParticipantId === memberOrParticipantId).memberOrParticipantId;
    let candidate = fetchMeetingParticipants(meeting).find(it => it.publicId === participantId);

    if (!!candidate) {
      name = candidate.name;
    } else if (meeting.provider.id === participantId) {
      name = `${meeting.provider.firstName} ${meeting.provider.lastName}`;
    }
  } catch (e) {
  }

  return name;
}

export function prettyFileSize(a: number, b?: any, c?: any, d?: any, e?: any) {
  return (b = Math, c = b.log, d = 1e3, e = c(a) / c(d) | 0, a / b.pow(d, e)).toFixed(2)
    + ' ' + (e ? 'kMGTPEZY'[--e] + 'B' : 'Bytes')
}

export function replaceAll(haystack: string, needle: string, value: string): string {
  while (haystack.indexOf(needle) > -1) {
    haystack = haystack.replace(needle, value);
  }
  return haystack
}

export function getUserConfirmation(msg: string, callback: (x: boolean) => void) {
  const allowTransition = window.confirm(msg);
  callback(allowTransition);
}

export function sortGroupsAscendingByName(list: AccountGroupDto[]): AccountGroupDto[] {
  return list.sort((g1, g2) => {
    return g1.name.toUpperCase() < g2.name.toUpperCase() ? -1 : 1;
  });
}

export function sortGroupsDescendingByName(list: AccountGroupDto[]): AccountGroupDto[] {
  return list.sort((g1, g2) => {
    return g1.name.toUpperCase() > g2.name.toUpperCase() ? -1 : 1;
  });
}

export function groupByMember(answerGroups: MemberAnswerGroupDto[]): { [key: string]: Array<MemberAnswerGroupDto>; } {
  return answerGroups
    .sort((g1, g2) => g1.member.firstName.toUpperCase() < g2.member.firstName.toUpperCase() ? -1 : 1)
    .reduce((acc: any, answerGroup: MemberAnswerGroupDto) => {
      let id = answerGroup.member.id
      acc[id] = acc[id] || [];
      acc[id].push(answerGroup);
      return acc;
    }, []);
}

export function isFirefox() {
  return adapter.browserDetails.browser === 'firefox'
}

export function notificationChannelProvided(participant: AccountDto | ParticipantDto): boolean {
  return !(/*!participant.notificationAccepted || */
    (!participant.smsNotification && !participant.emailNotification) ||
    (participant.smsNotification && (!participant.phone || participant.phone === DEFAULT_PHONE_PREFIX)) ||
    (participant.emailNotification && !participant.email) ||
    (!!participant.phone && !isValidNumber(participant.phone)) || (!!participant.email && !isValidEmail(participant.email)));
}

export function fetchMeetingParticipants(meeting: MeetingDto | PublicMeetingDto): ParticipantDto[] {
  let list: ParticipantDto[] | PublicParticipantDto[] = meeting.participants;
  meeting.groups.forEach(it => {
    list = [...list, ...it.participants]
  })
  return list.filter((value: ParticipantDto, index: number, self: ParticipantDto[]) => self.map(x => x.id).indexOf(value.id) === index);
}

export function isInThePast(meeting: MeetingDto | PublicMeetingDto): boolean {
  const start = fromTimestamp(meeting.startDate).add(meeting.duration, "minutes");
  return start < moment();
}

export function getParticipantActiveStatus(meetingInfo: MeetingInfo, publicId: string): boolean {
  let active = false;
  try {
    active = !!meetingInfo.sessions.find(it => it.memberOrParticipantId === publicId)
  } catch (e) {
  }

  return active;
}

export function sortAscendingTimestamps(key1: string, key2: string) {
  return moment(key1, MEETING_DATE_FORMAT) < moment(key2, MEETING_DATE_FORMAT) ? -1 : 1;
}

export function sortDescendingTimestamps(key1: string, key2: string) {
  return moment(key1, MEETING_DATE_FORMAT) > moment(key2, MEETING_DATE_FORMAT) ? -1 : 1;
}

export function getAccount(participant: ParticipantDto): AccountDto {
  if (!participant) {
    return {};
  } else {
    return {
      email: participant.email,
      name: participant.name,
      phone: participant.phone,
      gender: getAccountGender(participant.gender),
      smsNotification: participant.smsNotification,
      emailNotification: participant.emailNotification,
      notificationAccepted: participant.notificationAccepted,
      notifyBeforeMeeting: participant.notifyBeforeMeeting,
      agreementDate: participant.agreementDate,
      removed: participant.removed
    };
  }
}

export function getParticipant(account: AccountDto) {
  return {
    email: account.email,
    name: account.name,
    phone: account.phone,
    gender: getParticipantGender(account.gender),
    smsNotification: account.smsNotification,
    emailNotification: account.emailNotification,
    notificationAccepted: account.notificationAccepted,
    notifyBeforeMeeting: account.notifyBeforeMeeting,
    agreementDate: account.agreementDate,
    removed: account.removed
  } as ParticipantDto;
}

export function getAccountGender(gender: ParticipantDtoGenderEnum) {
  if (gender === ParticipantDtoGenderEnum.Female) {
    return AccountDtoGenderEnum.Female;
  }
  if (gender === ParticipantDtoGenderEnum.Male) {
    return AccountDtoGenderEnum.Male;
  }
  return AccountDtoGenderEnum.NotSpecified;
}

export function getParticipantGender(gender: AccountDtoGenderEnum) {
  if (gender === AccountDtoGenderEnum.Female) {
    return ParticipantDtoGenderEnum.Female;
  }
  if (gender === AccountDtoGenderEnum.Male) {
    return ParticipantDtoGenderEnum.Male;
  }
  return ParticipantDtoGenderEnum.NotSpecified;
}

export function getParticipantGroup(group: AccountGroupDto) {
  return {
    name: group.name,
    participants: group.accounts.map(it => getParticipant(it))
  } as ParticipantGroupDto;
}

export function doNothing() {
}

export function groupBy(arr: any[], key: string): {[key: string]: any[]} {
  return arr.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function isIpad() {
  return navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
}

export function iOS() {
  return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export function shouldLogout() {
  return isOwner() && isAppRoute(window.location.pathname);
}

export function isOwner() {
  return !Storage.getParticipantId() && !!Storage.getMemberId();
}

export function getAppSize() {
  return window.innerWidth >= 1280 ? Dimensions.DESKTOP : (window.innerWidth >= 768 ? Dimensions.TABLET : Dimensions.MOBILE);
}
export function isMac() {
  return navigator.platform.toUpperCase().indexOf('MAC') > -1;
}
function isWindows() {
  return navigator.platform.toUpperCase().indexOf('WIN') > -1
}

export function isDesktop() {
  return (isMac() || isWindows()) && !isIpad();
}
