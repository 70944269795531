import * as React from 'react';
import './TemporaryInfo.scss';
import { Alert } from 'antd';

interface TemporaryInfoProps {
  onClose: () => void;
}

export default function TemporaryInfo({ onClose }: TemporaryInfoProps) {
  return (
    <div className='temporary-info'>
    <Alert
      message="Unsere NOVENTI Videosprechstunde wird zum 30.11.2024 eingestellt. Wir bedanken uns bei unseren Kunden für das entgegengebrachte Vertrauen."
      banner
      closable
      icon={<span className='hide-icon' />}
      className='message'
      onClose={onClose}
    />
  </div>
  );
}
