const MID = 'mid';
const PID = 'pid';
const TKN = 'tkn';
const RFR = 'rfr';
const DID = 'did';
const TOKEN_REFRESH_REQUIRED = 'rtkn';
const REMEMBER_ME = 'rmb';
const USER_EMAIL = 'usr';
const MESSAGES = 'msgs';
const MACHINE_ID = 'machine-id';
const MICROPHONE_ID = 'mic-id';
const CAMERA_ID = 'cam-id';
const SPEAKER_ID = 'spk-id';
const PARTICIPANTS_IDS = 'usr-ids';
const GROUPS_IDS = 'grp-ids';
const SHOW_LOGIN_HINT ='hnt'
const SHOW_TEMPORARY_INFO ='tmp'

export const STORAGE_ROLE = 'role';

function set(key: string, value: any, storage: Storage = window.localStorage) {
  storage.setItem(key, value);
}

function get(key: string, storage: Storage = window.localStorage): any {
  return storage.getItem(key);
}

function remove(key: string, storage: Storage = window.localStorage) {
  storage.removeItem(key);
}


const Storage = {
  setValue(key: string, value: any) {
    set(key, value);
  },

  getValue(key: string) {
    return get(key);
  },

  removeValue(key: string) {
    return get(key);
  },

  setMemberId(memberId: string) {
    set(MID, memberId)
  },

  getMemberId(): string {
    return get(MID);
  },

  removeMemberId() {
    remove(MID);
  },

  setParticipantId(participantId: string) {
    set(PID, participantId, window.sessionStorage);
  },

  getParticipantId(): string {
    return get(PID, window.sessionStorage);
  },

  removeParticipantId() {
    remove(PID, window.sessionStorage);
  },

  setToken(token: string) {
    set(TKN, token, window.sessionStorage);
  },

  getToken(): string {
    return get(TKN, window.sessionStorage) || '';
  },

  setIsRefreshing(val: boolean) {
    set(RFR, val, window.sessionStorage);
  },

  removeIsRefreshing() {
    remove(RFR, window.sessionStorage);
  },

  isRefreshing() {
    return get(RFR, window.sessionStorage);
  },

  setActiveContentTab(tab: string) {
    set("active-content-tab", tab, window.sessionStorage);
  },

  getActiveContentTab(): string {
    return get("active-content-tab", window.sessionStorage) || '';
  },

  removeToken() {
    remove(TKN, window.sessionStorage);
  },

  setMachineId(id: string) {
    set(MACHINE_ID, id, window.localStorage);
  },

  getMachineId(): string {
    return get(MACHINE_ID, window.localStorage) || '';
  },

  removeMachineId() {
    remove(MACHINE_ID, window.localStorage);
  },

  setDeviceId(deviceId: string, email: string) {
    set(email + DID, deviceId);
  },

  getDeviceId(email: string) {
    return get(email + DID);
  },

  removeDeviceId(email: string) {
    remove(email + DID);
  },

  setTokenRefreshRequired(required: boolean) {
    set(TOKEN_REFRESH_REQUIRED, required);
  },

  isTokenRefreshRequired() {
    return get(TOKEN_REFRESH_REQUIRED);
  },

  removeTokenRefreshRequired() {
    remove(TOKEN_REFRESH_REQUIRED);
  },

  setRememberMe(value: boolean) {
    set(REMEMBER_ME, value)
  },

  getRememberMe() {
    return get(REMEMBER_ME);
  },

  removeRememberMe() {
    remove(REMEMBER_ME);
  },

  setUserEmail(value: string) {
    set(USER_EMAIL, (value));
  },

  getUserEmail(): string {
    return get(USER_EMAIL);
  },

  removeUserEmail() {
    remove(USER_EMAIL);
  },

  setMessages(value: string) {
    set(MESSAGES, (value));
  },

  getMessages(): string {
    return get(MESSAGES);
  },

  removeMessages() {
    remove(MESSAGES);
  },

  setMicrophoneId(meetingId: string, id: string) {
    set(`${MICROPHONE_ID}_${meetingId}`, id, window.sessionStorage);
  },

  getMicrophoneId(meetingId: string): string {
    return get(`${MICROPHONE_ID}_${meetingId}`, window.sessionStorage) || '';
  },

  setCameraId(meetingId: string, id: string) {
    set(`${CAMERA_ID}_${meetingId}`, id, window.sessionStorage);
  },

  getCameraId(meetingId: string): string {
    return get(`${CAMERA_ID}_${meetingId}`, window.sessionStorage) || '';
  },

  setSpeakerId(meetingId: string, id: string) {
    set(`${SPEAKER_ID}_${meetingId}`, id, window.sessionStorage);
  },

  getSpeakerId(meetingId: string): string {
    return get(`${SPEAKER_ID}_${meetingId}`, window.sessionStorage) || '';
  },

  setParticipantsIds(participantsIds: string[]) {
    set(PARTICIPANTS_IDS, participantsIds);
  },

  getParticipantsIds() {
    return get(PARTICIPANTS_IDS);
  },

  removeParticipantsIds() {
    remove(PARTICIPANTS_IDS);
  },

  setGroupsIds(groupsIds: string[]) {
    set(GROUPS_IDS, groupsIds);
  },

  getGroupsIds() {
    return get(GROUPS_IDS);
  },

  removeGroupsIds() {
    remove(GROUPS_IDS);
  },

  setShowLoginHint(show: boolean) {
    set(SHOW_LOGIN_HINT, show)
  },

  getShowLoginHint() {
    return get(SHOW_LOGIN_HINT)
  },

  removeShowLoginHint() {
    remove(SHOW_LOGIN_HINT);
  },

  setShowTemporaryInfo(show: boolean) {
    set(SHOW_TEMPORARY_INFO, show)
  },

  getShowTemporaryInfo() {
    return get(SHOW_TEMPORARY_INFO)
  },

  removeShowTemporaryInfo() {
    remove(SHOW_TEMPORARY_INFO);
  }
}

export default Storage;
